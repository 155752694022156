* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: space-between;
}

/*#region Main Classes*/
.container {
  max-width: 1320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  background-color: inherit;
}
.row {
  display: flex;
  flex-direction: row;
  background-color: inherit;
}
.ul {
  overflow-wrap: break-word;
}
.body-btn {
  font-weight: 500;
}
.body-main {
  font-family: HelveticaNeue, sans-serif;
  font-style: normal;
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #fefefe;
}
.caption {
  font-family: HelveticaNeue, sans-serif;
  font-style: none;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.5px;
  letter-spacing: unset;
}
.menu-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 29px 19px 30px 24px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.menu-item a:hover {
  color: #7558ff;
  outline: none;
}
.menu-item a {
  display: block;
  font-family: sans-serif;
  font-weight: 600;
  font-style: normal;
  margin: 0;
  line-height: 28px;
  letter-spacing: 0.04em;
  font-size: 19px;
  color: #fefefe;
}
.loader {
  display: flex;
  justify-self: center;
  align-items: center;
  background-color: white;
  opacity: 1;
  border: 8px solid #222942;
  border-top: 8px solid #fc9001;
  border-radius: 50%;
  width: 1.75em;
  height: 1.75em;
  animation: spin 5s linear infinite;
  transform: translate(-50%, -50%);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button {
  display: flex;
  color: #fefefe;
  background-color: linear-gradient(37.1deg, #ff754a, #ffbf44);
  width: fit-content;
  border-radius: 27px;
  padding: 14px 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  background: linear-gradient(90deg, #d644fa, #5937ff);
  border: none;
  cursor: pointer;
}
.button:hover {
  color: #fefefe;
  background: linear-gradient(37.1deg, #ff754a, #ffbf44);
}
.tooltip {
  position: relative;
  display: inline-block;
}
.hide-tooltip {
  visibility: hidden !important;
}
.tooltip .tooltiptext {
  width: 240px;
  height: auto;
  background-color: #252143;
  color: #fff;
  font-family: HelveticaNeue, sans-serif;
  text-align: left;
  border-radius: 20px;
  padding: 10px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -220px;
  margin-bottom: 10px;
}
/* Tooltip Arrow */
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 95px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffbf44 transparent transparent transparent;
}
.link {
  color: #ffbf44;
}
/*#endregion Main Classes*/

/*#region Header*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  border: 0;
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #b037ce, #5030eb 50%, #73e8e2);
  background-color: #1c1934;
}
.header-spacing-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
/*  Header Logo  */
.header-logo-container {
  display: flex;
  align-items: center;
}
.header-logo-wrapper {
  display: block;
  width: fit-content;
  height: fit-content;
  transform: scale(0.83);
}
.header-logo {
  width: 270px;
  height: 65px;
}
/*  Navigation Menu  */
.header-navigation-wrapper {
  display: flex;
  justify-content: flex-end;
}
.navigation-wrapper {
  display: flex;
  align-items: center;
}
.navigation-list,
.btn-list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
/* Request a Demo Button */
.navigation-button {
  display: flex;
  margin: 0px 10px !important;
  padding: 0;
  align-items: center;
  height: fit-content;
  z-index: 0;
}
.navigation-button span {
  border-radius: 27px;
  padding: 14px 30px;
  white-space: nowrap;
  transition: all 0.3s ease;
  background-color: #1c1934;
}
.btn-secondary {
  font-family: HelveticaNeue, sans-serif;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  justify-content: center;
  min-width: 117px;
  width: fit-content;
  border-radius: 27px;
  transition: all 0.3s ease;
  position: relative;
  text-align: center;
  color: #fefefe;
  background: #1c1934;
}
.btn-secondary::after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border-radius: 31px;
  z-index: -1;
  background: linear-gradient(90deg, #d644fa, #5937ff);
}
.btn-secondary:hover::after {
  color: #fefefe;
  background: linear-gradient(37.1deg, #ff754a, #ffbf44);
}
.btn-secondary:focus {
  color: #ff754a;
  background: linear-gradient(37.1deg, #ff754a, #ffbf44);
}
.buy-now-span {
  background: linear-gradient(to right top, #d644fa, #5937ff);
}
.buy-now-span:hover {
  background: linear-gradient(to right top, #ffbf44, #ff754a);
}
/*  Burger Menu */
.header-burger-button {
  display: none;
}
.burger-button {
  position: relative;
  display: none;
  max-width: 29px;
  height: 19px;
  cursor: pointer;
  width: 100%;
}
.burger-button span {
  width: 100%;
  transform: rotate(0deg);
  transition: all 0.3s ease;
}
.burger-button span:first-child {
  top: 0;
}
.burger-button span:nth-child(2),
.burger-button span:nth-child(3) {
  top: 8px;
}
.burger-button span:nth-child(4) {
  top: 16px;
}
.burger-button-active span:first-child,
.burger-button-active span:nth-child(4) {
  top: 10px;
  width: 0px;
  left: 50px;
}
.burger-button-active span:nth-child(2) {
  transform: rotate(45deg);
}
.burger-button-active span:nth-child(3) {
  transform: rotate(-45deg);
}
/*#endregion Header*/

/*#region Footer*/
.footer {
  bottom: 0;
  position: relative;
  right: 0;
  left: 0;
}
/* Footer Top */
.footer-top {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #252143;
  position: relative;
  z-index: 1;
}
.footer-column-left {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  width: 25%;
}
.footer-column-right {
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  width: 75%;
}
/*  Footer Top - Logo/Social Icons  */
.footer-logo {
  width: 221px;
  height: 53px;
  margin-bottom: 24px;
}
.footer-social-icons {
  display: flex;
}
.footer-social-icon {
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-right: 16px;
}
.footer-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  width: 24px;
  height: 24px;
}
/* Footer Top - Menu  */
.footer-menu {
  display: flex;
}
.footer-menu-product {
  margin-right: 91px;
}
.footer-menu-solutions {
  margin-right: 120px;
}
.footer-menu-company {
  margin-right: 99px;
}
.footer-menu-block {
  margin-top: 16px;
}
.footer-menu-title {
  display: block;
  margin-bottom: 33px;
  color: #fefefe;
}
.footer-menu-list {
  list-style-type: none;
  margin-bottom: 18px;
}
.footer-menu-item {
  list-style-type: none;
  margin-bottom: 18px;
}
.footer-menu-item a {
  font-weight: 400;
  font-style: normal;
  margin: 0;
  line-height: 22.5px;
  letter-spacing: unset;
  font-size: 16px;
  color: #b9b9da;
}
.footer-menu-item a:hover {
  color: #7558ff;
  outline: none;
}
/* Footer Divider */
.footer-vertical-line {
  border-left: 1px solid #b9b9da;
  margin-top: 16px;
  height: calc(100% - 32px);
}
.footer-horizontal-line {
  size: 1px;
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
  display: block;
  color: #b9b9da;
  margin-top: 40px;
}
/* Footer Menu Right */
.footer-menu-right {
  margin-top: 16px;
}
.footer-menu-right-list {
  margin-left: 90px;
  padding-left: 0;
}
.footer-menu-right-item {
  list-style-type: none;
  margin-bottom: 19px;
}
.footer-menu-right-item a {
  color: #fefefe;
}
.footer-menu-right-item a:hover {
  color: #7558ff;
  outline: none;
}
/* .footer-menu-right-whitepaper {
  display: inline-flex;
  align-items: center;
  margin-left: 90px;
  font-size: 18px;
  transition: all .3s ease;
  color: #fefefe;
  cursor: pointer;
  background: none;
  outline: none;
} */
/* Footer Bottom */
.footer-bottom {
  padding-top: 9px;
  padding-bottom: 8px;
  background-color: #7558ff;
}
.footer-bottom-wrapper {
  display: flex;
  align-items: center;
  background-color: inherit;
}
.footer-bottom-lupovis {
  background-color: inherit;
}
.footer-bottom-text {
  color: #1c1934;
  background-color: inherit;
}
.footer-bottom-production {
  margin-left: auto;
  margin-right: 20px;
}
/*#endregion Footer*/

/*#region Main Section*/
.ipinfo {
  display: flex;
  position: relative;
  padding-top: 310px;
  padding-bottom: 100px;
}
.rsm-svg {
  max-width: 1320px;
  margin-right: 100px;
  transform: translateY(-40px);
}
/*  Headline & Text */
.column-content {
  width: 60%;
  overflow: hidden;
}
.headline {
  display: block;
  font-family: sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #fefefe;
  margin: 0;
  font-size: 70px;
  line-height: 78px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
  word-break: break-word;
  overflow-y: hidden;
}
.banner-text {
  padding-right: 120px;
  margin-bottom: 40px;
}
.banner-text h2 {
  display: block;
  font-family: HelveticaNeue, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #fefefe;
  margin-bottom: 15px;
}
/* IP Info Table  */
.ipinfo-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
}
.search-wrapper {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin: 1%;
}
.search-box-wrapper {
  display: flex;
  flex-direction: row;
  margin: 1%;
  padding: 1% 2%;
  border-radius: 10px;
  width: 90%;
  height: auto;
  min-height: 40px;
  max-height: 40px;
  background-color: white;
}
.search-button {
  border: none;
  width: 92.5%;
  height: auto;
  background-color: white;
  font-size: medium;
}
.search-button::placeholder {
  color: grey;
}
.search-button:focus {
  outline: none;
}
.search-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
  background-color: white;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 10px;
}
.search-button-wrapper:hover {
  cursor: pointer;
}
.search-button-icon {
  background-color: white;
  height: 30px;
  width: 30px;
}
.query-output-wrapper {
  margin: 1%;
  padding: 1% 1%;
  border-radius: 10px;
  background-color: #1c1934;
  white-space: pre-line;
  overflow-x: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  min-height: 300px;
}
.tag {
  background-image: linear-gradient(to right, #ff754a, #ffbf44);
  border-radius: 3px 0 0 3px;
  color: black;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
}
.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}
.tag::after {
  background: #1c1934;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #ffbf44;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}
.table-tag {
  background-image: linear-gradient(to right, #ff754a, #ffbf44);
  border-radius: 3px 0 0 3px;
  color: black;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  white-space: nowrap;
}
.table-tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}
.table-tag::after {
  background: #f3f3f3;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #ffbf44;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
}
.data-row {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background-color: inherit;
  width: 100%;
  color: white;
  background-color: transparent;
}
.att-dest-countries {
  white-space: pre;
  overflow-wrap: wrap !important;
  position: relative;
  flex-wrap: wrap;
}
.indent-row {
  transform: translateX(40px);
}
.mini-logo {
  background-color: inherit;
  height: 30px;
  width: 30px;
  background-position: center;
}
.data-text {
  padding-left: 10px;
  background-color: inherit;
}
.home-page-button-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.view-pricing-button {
  width: 300px !important;
  margin: auto auto;
}
.insights-button {
  display: flex;
  font-family: HelveticaNeue, sans-serif;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.01em;
  justify-content: center;
  min-width: 300px;
  padding: 0 20px;
  border-radius: 50px;
  transition: all 0.3s ease;
  position: relative;
  text-align: center;
  color: #fefefe;
  margin-bottom: 10px;

  /* Gradient border - pink to purple */
  background: linear-gradient(to right top, #d644fa, #5937ff);
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  border: 3px solid transparent;
}
/* On hover, change the border color */
.insights-button:hover {
  background: linear-gradient(to right top, #ffbf44, #ff754a);
  cursor: pointer;
}
/*#endregion Main Section*/

/*#region Media Queries - Max-width: 1360px*/
@media (max-width: 1360px) {
  .container {
    padding-right: 2%;
    max-width: 100%;
  }
  .menu-item a {
    font-size: 16px;
  }
  .navigation-button {
    margin-left: 20px !important;
  }
  .navigation-button span {
    font-size: 16px;
  }
  .footer {
    position: relative;
  }
  .footer-menu-product,
  .footer-menu-solutions,
  .footer-menu-company {
    margin-right: 60px;
  }
  .footer-menu-right-list {
    margin-left: 60px;
  }
  .rsm-svg {
    margin-right: 75px;
    transform: translateY(-35px);
  }
}
/*#endregion Media Queries - Max-width: 1360px*/

/*#region Media Queries - Max-width: 1200px*/
@media (max-width: 1200px) {
  .container {
    max-width: 100%;
  }
  .menu-item {
    padding: 29px 15px 30px 15px;
  }
  .navigation-button {
    margin: 0px 0px 0px 10px !important;
  }
  .buy-now-btn span {
    padding: 15px 19px 15px 19px !important;
  }
  .navigation-wrapper {
    padding-right: 5px;
    padding-left: 5px;
  }
  .header-logo {
    width: 189px;
    height: 46px;
  }
  .menu-item a {
    font-size: 16px;
  }
  .navigation-button span {
    padding: 15px;
  }
  .rsm-svg {
    margin-right: 50px;
    transform: translateY(-30px);
  }
}
/*#endregion Media Queries - Max-width: 1200px*/

/*#region Media Queries - Max-width: 991px*/
@media (max-width: 991px) {
  /*   Main Classes */
  .container {
    padding-right: 25px;
    padding-left: 25px;
    max-width: 720px;
  }
  .row {
    flex-direction: column;
  }
  /*  Header  */
  .header {
    padding: 15px 0 9px;
  }
  .navigation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .header-logo {
    width: 189px;
    height: 46px;
  }
  .header-logo-wrapper {
    transform: scale(1);
  }
  .navigation {
    position: fixed;
    z-index: 50;
    top: 75px;
    left: 0;
    padding: 0 25px;
    width: 100%;
    height: 100vh;
    background-color: #1c1934;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  .navigation-open {
    padding-bottom: 150px;
    transform: translateX(0);
    overflow: scroll;
  }
  .navigation-list {
    padding-top: 35px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .btn-list {
    display: flex;
    margin: 20px 0 20px 0;
    width: 100%;
    flex-direction: column;
  }
  .btn-list li {
    margin: 10px 0 10px 0;
  }
  .navigation-list li {
    width: 100%;
    border-bottom: 1px solid rgba(185, 185, 218, 0.2);
  }
  .navigation-list li a {
    width: 100%;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.0198em;
  }
  .burger-button {
    display: block;
    color: #fefefe;
    margin-right: 7px;
    margin-bottom: 6px;
    width: 100%;
  }
  .burger-button span {
    display: block;
    position: absolute;
    height: 3px;
    background-color: #fefefe;
    border-radius: 28px;
    opacity: 1;
    left: 0;
    width: 100%;
  }
  /*  Main Section  */
  .ipinfo {
    padding: 0;
    padding-top: 140px;
    margin-bottom: 100px;
  }
  .headline {
    font-size: 40px;
    line-height: 50px;
    word-break: break-word;
  }
  .ipinfo-container {
    width: 100%;
  }
  .banner-text {
    padding-right: unset;
    margin-bottom: 30px;
  }
  .column-content {
    width: 100%;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  /*  Footer  */
  .footer-top {
    padding: 0;
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-social-icons {
    margin-bottom: 24px;
  }
  .footer-social-icon {
    margin-right: 30px;
  }
  .footer-column-right {
    flex-direction: column;
  }
  .footer-column-right {
    width: 100%;
  }
  .footer-menu-title {
    margin-bottom: 15px;
  }
  .footer-menu-right {
    margin-top: 24px;
  }
  .footer-menu-right-list {
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: space-between;
    align-content: center;
  }
  .footer-menu-right-item {
    margin: 0;
  }
  .footer-menu-right-item a {
    font-size: 18px;
  }
  .footer-horizontal-line {
    height: 1px;
  }
  .rsm-svg {
    margin-right: 50px;
    transform: translateY(0);
  }
  /*  Consent Popup  */
  .consent-model-container {
    width: 75%;
  }
  .consent-model-container header,
  .consent-model-container footer p {
    font-size: 14px;
  }
  .switch {
    width: 45px;
    height: 25.5px;
  }
  .slider::before {
    height: 19.5px;
    width: 19.5px;
    left: 3px;
    bottom: 3px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(19.5px);
    -ms-transform: translateX(19.5px);
    transform: translateX(19.5px);
  }
  .button {
    padding: 10.5px 22.5px;
    font-size: 14px;
    line-height: 19.5px;
  }
}
/*#endregion Media Queries - Max-width: 991px*/

/*#region Media Queries - Max-width: 768px*/
@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
  .footer-menu {
    justify-content: space-between;
  }
  .footer-menu-product,
  .footer-menu-solutions,
  .footer-menu-company {
    width: 30%;
    margin-right: 0px;
  }
}
/*#endregion Media Queries - Max-width: 768px*/

/*#region Media Queries - Max-width: 575px*/
@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
  .footer-menu {
    justify-content: space-between;
  }
  .footer-menu-product,
  .footer-menu-solutions,
  .footer-menu-company {
    max-width: 33.3%;
    margin-right: 0px;
  }
  .footer-menu span {
    font-size: 16px;
  }
  .footer-menu ul li a {
    font-size: 14px;
  }
  .footer-menu-right-item a {
    font-size: 16px;
  }
  .consent-model-container {
    width: 90%;
    padding: 10px;
  }
  .consent-model-container header,
  .consent-model-container footer p {
    font-size: 12px;
    padding-bottom: 10px;
  }
  .consent-container p {
    font-size: 14px;
  }
  .switch {
    width: 30px;
    height: 17px;
  }
  .slider::before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  .button {
    padding: 7px 15px;
    font-size: 14px;
    line-height: 19.5px;
  }
}
/*#endregion Media Queries - Max-width: 575px*/

/*#region Media Queries - Max-height: 600px*/
@media (max-height: 600px) {
  .consent-model-container {
    width: 90%;
    padding: 10px;
  }
  .consent-model-container header,
  .consent-model-container footer p {
    font-size: 12px;
    padding-bottom: 10px;
  }
  .consent-container p {
    font-size: 14px;
  }
  .switch {
    width: 30px;
    height: 17px;
  }
  .slider::before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  .button {
    padding: 7px 15px;
    font-size: 14px;
    line-height: 19.5px;
  }
}
/*#endregion Media Queries - Max-height: 600px*/

/*#region Media Queries - Max-height: 410px*/
@media (max-height: 410px) {
  .consent-model-container {
    max-width: 800px;
  }
  .consent-model-container footer {
    align-items: flex-start;
  }
  .consent-model-container button {
    margin-left: auto;
    margin-right: auto;
  }
}
/*#endregion Media Queries - Max-height: 410px*/

/* Media query for screens with a maximum width of 768px */
@media screen and (max-width: 768px) {
  .report-table-wrapper {
    width: 90%;
    height: 40vh;
  }
  .report-table {
    font-size: 8px;
  }
  .insights-button {
    font-size: 16px;
  }
}

/* Media query for screens with a maximum width of 576px */
@media screen and (max-width: 576px) {
  /* Hide the 2nd and 3rd columns on mobile */
  .report-table th:nth-child(3),
  .report-table td:nth-child(3),
  .report-table th:nth-child(4),
  .report-table td:nth-child(4) {
    display: none;
  }
  /* Reduce font-size for better display on small screens */
  .report-table {
    font-size: 8px;
  }
  .report-table-wrapper {
    width: 100%;
    height: 100%;
  }
  .report-table {
    font-size: 6px;
  }
  .insights-button {
    width: 100%; /* Set the button to full width */
    min-width: initial; /* Reset the min-width */
    white-space: no-wrap; /* Prevent the text from wrapping */
  }
}

/* Media query for screens with a maximum width of 992px */
@media screen and (max-width: 992px) {
  .report-table-wrapper {
    width: 100%;
    height: 50vh;
  }
  .report-table {
    font-size: 9px;
  }
  .insights-button {
    font-size: 16px;
  }
}

/* Media query for screens with a minimum width of 1200px */
@media screen and (min-width: 1200px) {
  .report-table-wrapper {
    width: 70%;
    height: 60vh;
  }
  .report-table {
    font-size: 10px;
  }
  .insights-button {
    font-size: 18px;
  }
}

/* Media query for screens with a minimum width of 1600px */
@media screen and (min-width: 1600px) {
  .report-table-wrapper {
    width: 60%;
    height: 55vh;
  }
  .report-table {
    font-size: 12px;
  }
  .insights-button {
    font-size: 20px;
  }
}
