/* GeoMap Styling Sheet */

.attack_line {
  stroke-dasharray: 0;
  animation: dash 10s linear;
}

@keyframes dash {
  0% {
    stroke-dasharray: 0 0 0 822;
  }
  100% {
    stroke-dasharray: 822 0 822 822;
  }
}
