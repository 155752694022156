/* Insights Styling Sheet */

.insights {
  padding: 0;
  padding-top: 140px;
  margin-bottom: 100px;
}

/* Custom scrollbar style for Chrome, Edge, and Safari */
.report-table-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.report-table-wrapper {
  width: 100%;
  overflow-y: scroll;
  max-height: 40vh;
  min-height: 600px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Edge */
}

.report-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
  font-family: sans-serif;
  text-align: left;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.report-table thead {
  background-color: #1c1934;
  position: sticky;
  top: 0;
  z-index: 1;
}

.report-table th {
  padding: 12px 15px;
  color: white;
}

.report-table td {
  padding: 12px 15px;
}

.report-table tbody tr {
  border-bottom: 1px solid #dddddd;
  background-color: #f3f3f3;
}

.report-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.report-table tbody tr:last-of-type {
  border-bottom: 2px solid #4b4561;
}

.report-table-wrapper h1 {
  font-size: large;
  color: #df9217;
  height: 100%;
  text-align: center;
  padding-top: 25%;
}

.custom-background {
  background-color: #f3f3f3;
}

.unlock-list-button-wrapper {
  display: flex;
  margin: auto auto;
  padding: 20px 0 20px 0;
}
