body {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  color: #000;
  background-color: #1c1934;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Helvetica Neue';
}

a {
  text-decoration: none;
  transition: color .3s ease;
  cursor: pointer;
}
